<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    :show-export="true"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @info="handleInfo"
    @export="handleExport"
  >
    <template #status="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div class="form-status" :class="{ draft: value === 'Draft' }">
          <BaseIcon name="mdi-circle" size="8px" inherit-color />

          <div class="q-ml-sm">{{ value }}</div>
        </div>
      </div>
    </template>

    <template #entries="{ column, value, item }">
      <div
        v-if="item.entriesLink"
        class="stat"
        @click.stop="$emit('entries', item.id)"
      >
        <div class="label">{{ column }}</div>

        <q-space />

        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "FormGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSelect(formId) {
      this.$emit("select", formId);
    },

    handleEdit(formId) {
      this.$emit("edit", formId);
    },

    handleDelete(formId) {
      this.$emit("delete", formId);
    },

    handleRestore(formId) {
      this.$emit("restore", formId);
    },

    handleMore(formId) {
      this.$emit("more", formId);
    },

    handleInfo(formId) {
      this.$emit("info", formId);
    },

    handleExport(formId) {
      this.$emit("export", formId);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}

.form-entries {
  text-decoration: underline;
  color: var(--secondary);
}
</style>
