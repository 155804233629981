<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    :show-export="true"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @info="handleInfo"
    @export="handleExport"
  >
    <template #status="{ value }">
      <div id="form-status" :class="{ draft: value === 'Draft' }">
        <BaseIcon name="mdi-circle" size="8px" inherit-color />

        <div class="q-ml-sm">{{ value }}</div>
      </div>
    </template>

    <template #entries="{ value, item }">
      <div
        v-if="item.entriesLink"
        class="stat"
        @click.stop="$emit('entries', item.id)"
      >
        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "FormList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSelect(formId) {
      this.$emit("select", formId);
    },

    handleEdit(formId) {
      this.$emit("edit", formId);
    },

    handleDelete(formId) {
      this.$emit("delete", formId);
    },

    handleRestore(formId) {
      this.$emit("restore", formId);
    },

    handleMore(formId) {
      this.$emit("more", formId);
    },

    handleInfo(formId) {
      this.$emit("info", formId);
    },

    handleExport(formId) {
      this.$emit("export", formId);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}
</style>
